import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabPanes, Topbar, PageLoader, toast } from "@vilocnv/allsetra-core";
import { Box, useTheme } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { PageLoaderCentered } from "components/sections/objects/ObjectDetailsSection/ObjectDetailsSection.styled";

// Data
import { useActiveObjectById, useAppDispatch, useAppSelector } from "hooks";
import { getObjectFeaturesThunk, setObjectActiveTabIndex } from "app/features";
import {
  selectDrawerSelectedAccountId,
  selectIsDrawerCollapsed,
  selectObjectActiveTabIndex,
  selectObjectFeaturesState,
} from "app/data/selectors";
import {
  OBJECTS_DETAILS_TABS_HEADINGS,
  OBJECTS_DETAILS_TABS_INDEX_TO_ROUTENAME_MAPPING,
} from "app/data/constants";
import { useTranslation } from "react-i18next";

const ObjectTabsLayout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Global State
  const isDrawerCollapsed = useAppSelector(selectIsDrawerCollapsed);
  const objectActiveTabIndex = useAppSelector(selectObjectActiveTabIndex);
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { objectFeaturesConfig, objectFeaturesLoading } = useAppSelector(
    selectObjectFeaturesState
  );

  const { specificObject, loading } = useActiveObjectById();

  const { t } = useTranslation();

  const tabsHeadings = useMemo(
    () => OBJECTS_DETAILS_TABS_HEADINGS(t, objectFeaturesConfig),
    [t, objectFeaturesConfig]
  );

  useEffect(() => {
    if (location.pathname.includes("details")) {
      dispatch(setObjectActiveTabIndex(0));
    } else if (location.pathname.includes("rides")) {
      dispatch(setObjectActiveTabIndex(1));
    }
  }, [location.pathname]);

  const fetchSpecificObjectFeatures = async () => {
    const { type } = await dispatch(
      getObjectFeaturesThunk({
        accountId: drawerSelectedAccountId ?? "",
        objectId: params.id ?? "",
      })
    );

    if (type === "objects/getObjectFeaturesThunk/rejected") {
      navigate(-1);
      toast.error("Object features were not found.");
    }
  };

  useEffect(() => {
    fetchSpecificObjectFeatures();
  }, [drawerSelectedAccountId, params.id]);

  const onChangeTab = (value: number) => {
    dispatch(setObjectActiveTabIndex(value));
    navigate(
      `/dashboard/objects/${OBJECTS_DETAILS_TABS_INDEX_TO_ROUTENAME_MAPPING[value]}/${params.id}`
    );
  };

  return (
    <Box>
      {loading || objectFeaturesLoading ? (
        <PageLoaderCentered isDrawerCollapsed={isDrawerCollapsed}>
          <PageLoader />
        </PageLoaderCentered>
      ) : (
        <Box>
          <Topbar
            theme={theme}
            title={specificObject?.name || "Object"}
            breadcrumbTitle="Objects"
            breadcrumbRedirectTo={() => navigate("/dashboard/objects")}
            primaryButton={{
              id: "object-settings",
              variant: "contained",
              text: t("buttonsText.manageObject"),
              onClick: () =>
                navigate(
                  `/dashboard/objects/${specificObject?.uniqueId}/settings`
                ),
              startIcon: <Settings />,
            }}
          />
          <Box>
            <TabPanes
              value={objectActiveTabIndex}
              onChange={onChangeTab}
              headings={tabsHeadings}
            >
              <Box mx={2}>{children}</Box>
            </TabPanes>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ObjectTabsLayout;
