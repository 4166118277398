import { IFeatures } from "app/data/types";
import { types } from "@vilocnv/allsetra-core";
import {
  MapSvg,
  GeozonesSvg,
  MembersSvg,
  GroupsSvg,
  AccountIcon,
  ObjectsIcon,
  Reports,
  keysInactiveIcon,
  AccountSettings,
} from "assets/icons";
import {
  ActiveMapIcon,
  ActiveGeozoneIcon,
  ActiveObjectsIcon,
  ActiveUserIcon,
  ActiveGroupsIcon,
  ActiveAccountIcon,
  ActiveReportsIcon,
  ActiveKeysIcon,
  ActiveAccountSettingsIcon,
} from "components/ui/icons";

export const getDrawerMenuItems = (t: any): Array<types.IDrawerMenuItem> => [
  {
    id: "map",
    name: t("drawerMenuLinks.map"),
    icon: MapSvg,
    path: "/dashboard",
    activeIcon: ActiveMapIcon,
  },
  {
    id: "geozones",
    name: t("drawerMenuLinks.geozones"),
    icon: GeozonesSvg,
    path: "/dashboard/geozones",
    activeIcon: ActiveGeozoneIcon,
  },
  {
    id: "objects",
    name: t("drawerMenuLinks.objects"),
    icon: ObjectsIcon,
    path: "/dashboard/objects",
    activeIcon: ActiveObjectsIcon,
  },
  {
    id: "users",
    name: t("drawerMenuLinks.users"),
    icon: MembersSvg,
    path: "/dashboard/users",
    activeIcon: ActiveUserIcon,
  },
  {
    id: "accounts",
    name: t("drawerMenuLinks.accounts"),
    icon: AccountIcon,
    path: "/dashboard/accounts",
    activeIcon: ActiveAccountIcon,
  },
  {
    id: "groups",
    name: t("drawerMenuLinks.groups"),
    icon: GroupsSvg,
    path: "/dashboard/groups",
    activeIcon: ActiveGroupsIcon,
  },
];

export const getDrawerSubMenuLists = (
  t: any,
  features: IFeatures,
  accountId: string
): {
  [x: string]: Array<types.IDrawerMenuItem>;
} => ({
  [t("drawerMenuLinks.admin")]: [
    {
      id: features.isRidesEnabled ? "mileage-report" : "tag-position-report",
      name: t("drawerMenuLinks.reports"),
      icon: Reports,
      path: features.isRidesEnabled
        ? "/dashboard/reports/mileage-registration"
        : "/dashboard/reports/tag-position",
      activeIcon: ActiveReportsIcon,
    },
    {
      id: "keys-manager",
      name: t("drawerMenuLinks.keysManager"),
      icon: keysInactiveIcon,
      path: "/dashboard/keys-manager",
      activeIcon: ActiveKeysIcon,
    },
    {
      id: "account-settings",
      name: t("drawerMenuLinks.accountSettings"),
      icon: AccountSettings,
      activeIcon: ActiveAccountSettingsIcon,
      path: "/dashboard/accounts/settings/" + accountId,
    },
  ],
});

export const FIETSGESTOLEN_EXTERNAL_LINK = "https://www.enra.nl/tweede-slot/";

export const yesNoOptionsForDropdown = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const paymentTermInDaysOptions = [
  { days: 30, name: "1 month" },
  { days: 90, name: "3 months" },
  { days: 180, name: "6 month" },
];

export const DEFAULT_COORDINATES = { lat: 52.0, lng: 5.301137 };
