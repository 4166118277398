import { styled, Box } from "@mui/material";

//
// INFORMATION FORM
//
export const InformationFormContainer = styled(Box)(({ theme }) => ({
  width: "40vw",

  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const InformationTabsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "8px",

  "& button.MuiTab-root": {
    display: "flex",
    width: "112px",
    height: "48px",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "6px",
    border: "1px solid var(--gray-200, #EFF4FF)",
    background: "#FFF",

    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "36px",
      marginRight: "20px",
    },
  },

  "& button.MuiTab-root.Mui-selected": {
    border: "1px solid var(--brand-primary-500, #00B2A3)",
    background: "var(--brand-primary-100, #F5FFFE)",
    color: "var(--gray-900, #1D1F2B);",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },

  ">div>div": {
    margin: "0px",
  },
}));

export const InformationTabHeading = styled("p")({
  color: "var(--gray-700, #323946)",
  fontFamily: "Gilroy",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "112%",
});

//
// PAYMENT FORM
//
export const PaymentFormContainer = styled(Box)(({ theme }) => ({
  width: "60%",
  display: "flex",
  flexDirection: "column",
  gap: "48px",

  "& h5": {
    color: "var(--gray-900, #1D1F2B)",
    fontFamily: "Gilroy",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "115%",
  },

  ".css-1uc33dm": {
    fontSize: "10px",
  },

  "& .MuiTypography-root": {
    color: "#000",
    fontFamily: "Gilroy",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "115%",
  },

  [theme.breakpoints.down("md")]: {
    ".css-1uc33dm": {
      fontSize: "8px",
    },
  },

  [theme.breakpoints.down("sm")]: {
    width: "90%",

    "& .MuiTypography-root": {
      fontSize: "10px",
    },
  },
}));

export const TermsHeading = styled("h5")({
  color: "#1D1F2B",
  fontFamily: "Gilroy",
  fontWeight: `600 !important`,
  lineHeight: "115%",
  marginLeft: "-16px",
});

export const LabelText = styled("p")({
  color: "#212B33",
  fontFamily: "Gilroy",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "13.8px",
  marginLeft: "-16px",
});

export const LabelLinkText = styled("a")({
  color: "#00B2A3",
  fontFamily: "Gilroy",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "13.8px",
  textDecoration: "underline",
});

export const CheckBoxContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});
