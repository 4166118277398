import React, {
  FC,
  useState,
  useCallback,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { FormikHelpers } from "formik";
import {
  GoogleMap,
  DrawingManagerF,
  PolygonF,
  CircleF,
  Marker,
} from "@react-google-maps/api";
import { PageLoader, types, toast } from "@vilocnv/allsetra-core";
import {
  GeozonesMapContainer,
  IconBox,
  IconTooltip,
  IconsWrapper,
} from "./GeozonesMap.styled";
import PlacesSearchTopbar from "components/common/PlacesSearchTopbar/PlacesSearchTopbar";
import GeozoneForm from "components/forms/GeozoneForm/GeozoneForm";
import Markers from "../common/Markers";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector, useMap } from "hooks";
import {
  CIRCLE_HIGHLIGHT_OPTIONS,
  CIRCLE_OPTIONS,
  googleMapStyles,
  HIGHLIGHTS_COLORS,
  POLYGON_HIGHLIGHTS_OPTIONS,
  POLYGON_OPTIONS,
} from "app/data/constants";
import { IAddGeozone } from "app/data/types";
import {
  selectDrawerSelectedAccountId,
  selectActiveGeozoneId,
  selectObjectsState,
} from "app/data/selectors";
import {
  addGeozoneReducer,
  createOrEditGeozoneThunk,
  getAllGeozonesThunk,
  updateGeozoneGeoJsonById,
  setActiveGeozoneId,
  removeTemporaryGeozones,
} from "app/features";
import {
  isObject,
  transformGeoJSONCoordsToPaths,
  signalRGenerateSuccessToastMessage,
  createGeozoneObjectForNewlyDrawedPolygon,
  getGeoJSONFromBoundingPaths,
  createGeozoneObjectForNewlyDrawedCircle,
  getBoundsZoomLevel,
  getElementSize,
  extractObjectsDataAsMarkersForMap,
} from "app/data/helpers";
import { AccountPersonBlack, AccountPersonGray } from "assets/icons";
import { MapViewport } from "components/common/MapViewport";
import { SignalRService } from "app/data/services";

export interface GeozonesMapProps {
  geozones: Array<types.IGeozone>;
  objects?: Array<types.IObject>;
  showSearchTopbar?: boolean;
  drawingMode?: boolean;
  creationMode?: "add" | "edit" | null;
  setDrawingMode?: (value: boolean) => void;
  resetToGeozonesListing?: () => void;
  drawingShape?: string;
  setDrawingShape?: (value: string) => void;
  circleSize?: number;
  setCircleSize?: (value: number) => void;
}

const GeozonesMap: FC<GeozonesMapProps> = ({
  geozones,
  objects,
  showSearchTopbar = false,
  drawingMode = false,
  creationMode = null,
  setDrawingMode,
  resetToGeozonesListing,
  drawingShape,
  setDrawingShape,
  circleSize,
  setCircleSize,
}) => {
  const dispatch = useAppDispatch();

  const alarmContainerRef = useRef<HTMLDivElement>();

  const { mapRef, isLoaded, loadError, centerCoords, onMapLoad } = useMap({
    disableNavigator: true,
  });

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const activeGeozoneId = useAppSelector(selectActiveGeozoneId);
  const { allObjects } = useAppSelector(selectObjectsState);

  // Local State
  const [mapKey, setMapKey] = useState(0);
  const [showAccountsObjects, setShowAccountsObjects] = useState(false);
  const [geozoneFormOpen, setGeozoneFormOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [drawingManager, setDrawingManager] = useState<
    google.maps.drawing.DrawingManager | undefined
  >();
  const [mapZoom, setMapZoom] = useState(8);

  const [circle, setCircle] = useState<google.maps.Circle | null>(null);
  const [circleCenter, setCircleCenter] = useState<
    google.maps.LatLng | undefined
    //@ts-ignore
  >(centerCoords);

  const [customMarkers, setCustomMarkers] = useState([]);

  // Define refs and states for Polygon instance and listeners
  const polygonRef = useRef<google.maps.Polygon>();
  const circleRef = useRef<google.maps.Circle>();
  const listenersRef = useRef([]);

  const toggleGeozoneFormOpen = () => {
    setGeozoneFormOpen(!geozoneFormOpen);
  };

  const handleMarkerClick = (markerIndex: number) => {
    setSelectedMarker(markerIndex);
  };

  const onCloseGeozoneForm = () => {
    toggleGeozoneFormOpen();
    setMapKey((mapKey) => mapKey + 1);
  };

  const onDiscardChangesHandler = useCallback(() => {
    dispatch(setActiveGeozoneId(null));
    dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
    setMapKey((mapKey) => mapKey + 1);
    resetToGeozonesListing && resetToGeozonesListing();
  }, []);

  const onPolygonComplete = useCallback(
    (polygon: google.maps.Polygon) => {
      const geozone = createGeozoneObjectForNewlyDrawedPolygon(polygon);
      dispatch(addGeozoneReducer(geozone));
      setDrawingMode && setDrawingMode(false);
      drawingManager?.setDrawingMode(null);
    },
    [drawingManager]
  );

  const onCircleComplete = useCallback(
    (circle: google.maps.Circle, creationMode?: string | null) => {
      const geozone = createGeozoneObjectForNewlyDrawedCircle(circle);
      if (creationMode === "add") {
        dispatch(addGeozoneReducer(geozone));
      } else {
        dispatch(
          updateGeozoneGeoJsonById({
            id: activeGeozoneId || "",
            geoJson: geozone.geoJson,
          })
        );
      }
      setDrawingMode && setDrawingMode(false);
      drawingManager?.setDrawingMode(null);
    },
    [drawingManager, circleSize]
  );

  // Call setPolygonPaths with new edited path
  const onCircleEdit = useCallback(() => {
    if (circleRef.current) {
      const geoJson = JSON.stringify(
        createGeozoneObjectForNewlyDrawedCircle(circleRef.current)
      );

      dispatch(
        updateGeozoneGeoJsonById({ id: activeGeozoneId || "", geoJson })
      );
    }
  }, [geozones, activeGeozoneId, circleRef]);

  // Call setPolygonPaths with new edited path
  const onPolygonEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      const geoJson = JSON.stringify(getGeoJSONFromBoundingPaths(nextPath));

      dispatch(
        updateGeozoneGeoJsonById({ id: activeGeozoneId || "", geoJson })
      );
    }
  }, [geozones, activeGeozoneId, polygonRef]);

  // Bind refs to current Polygon and listeners
  const onPolygonLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      setDrawingShape && setDrawingShape("polygon");
      listenersRef.current.push(
        // @ts-ignore
        path.addListener("set_at", onPolygonEdit),
        path.addListener("insert_at", onPolygonEdit),
        path.addListener("remove_at", onPolygonEdit)
      );
    },
    [onPolygonEdit, polygonRef]
  );

  const onCircleLoad = useCallback(
    (circle: google.maps.Circle, apiRadius: number) => {
      circleRef.current = circle;
      setCircle(circle);
      const center = circle.getCenter();
      setCircleSize && setCircleSize(apiRadius);
      setDrawingShape && setDrawingShape("circle");
      // @ts-ignore
      setCircleCenter && setCircleCenter(center);
    },
    [onCircleEdit, circleRef]
  );

  // Clean up refs
  const onPolygonUnmount = useCallback(() => {
    // @ts-ignore
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = undefined;
  }, [polygonRef]);

  const onSubmitHandler = async (
    values: IAddGeozone,
    formikHelpers: FormikHelpers<IAddGeozone>
  ) => {
    formikHelpers.setSubmitting(true);
    setSubmitting(true);

    const geozone = geozones.find((geo) => geo.uniqueId === activeGeozoneId);

    const { type } = await dispatch(
      createOrEditGeozoneThunk({
        accountId: drawerSelectedAccountId,
        data: {
          ...values,
          geoJson: geozone?.geoJson,
          isEnabled: true,
          ...(creationMode === "edit" ? { uniqueId: geozone?.uniqueId } : null),
        },
      })
    );

    if (type === "geozones/createOrEditGeozoneThunk/fulfilled") {
      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.AccountGeozoneCreatedEvent ||
          event.eventName === types.BackendEventsEnum.AccountGeozoneUpdatedEvent
        ) {
          formikHelpers.setSubmitting(false);
          formikHelpers.resetForm();
          setSubmitting(false);
          onCloseGeozoneForm();
          toast.success(
            signalRGenerateSuccessToastMessage(
              event.name,
              "Geozone",
              creationMode === "edit" ? "updated" : "created"
            )
          );
          dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
          resetToGeozonesListing && resetToGeozonesListing();
          dispatch(setActiveGeozoneId(null));
        }

        SignalRService.hubConnection?.off("EventRaised");
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
    }
  };

  const onLocationChange = (value: any) => {
    if (isEmpty(value)) return;
    mapRef?.panTo({ lat: value.lat, lng: value.long });
  };

  useEffect(() => {
    if (allObjects && allObjects?.length > 0) {
      // @ts-ignore
      setCustomMarkers(extractObjectsDataAsMarkersForMap(allObjects));
    }
  }, [allObjects]);

  useEffect(() => {
    if (!isEmpty(geozones)) {
      //@ts-ignore
      if (objects?.length > 0) {
        setMapZoom(12);
      } else {
        if (geozones.length > 0 && geozones.length < 2) {
          const geozone = geozones[0];
          const geoJSON = JSON.parse(geozone.geoJson);
          const coords =
            geoJSON.geometry?.coordinates || geoJSON.Geometry?.Coordinates;
          const paths = transformGeoJSONCoordsToPaths(coords);

          const transformedCoords = coords?.map((item: any) => ({
            latitude: item[0],
            longitude: item[1],
          }));

          var bounds = new google.maps.LatLngBounds();

          if (paths && paths.length > 0) {
            const zoomLevel = getBoundsZoomLevel(
              transformedCoords,
              getElementSize(alarmContainerRef.current)
            );

            paths.map((coord) =>
              bounds.extend({ lat: coord.lat, lng: coord.lng })
            );

            mapRef?.fitBounds(bounds);
            //@ts-ignore
            mapRef?.setZoom(geozones.length === 1 ? zoomLevel : 7);

            const properties = geoJSON.properties || geoJSON.Properties;

            if (!isEmpty(properties)) {
              const centerPoint =
                properties.centerPoint || properties.CenterPoint;

              const center = {
                lat: centerPoint[0] || 0,
                lng: centerPoint[1] || 0,
              };
              //@ts-ignore
              setCircleCenter(center);
            }
          }
        }
      }
    }
  }, [geozones, objects]);

  const handleShapeChange = (shape: string) => {
    dispatch(removeTemporaryGeozones());
    setDrawingShape && setDrawingShape(shape);
    setDrawingMode && setDrawingMode(true);
    setMapKey((mapKey) => mapKey + 1);
  };

  const handleResetShape = () => {
    dispatch(removeTemporaryGeozones());
    setDrawingMode && setDrawingMode(true);
    setMapKey((mapKey) => mapKey + 1);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const newCenter = event.latLng;
    newCenter && setCircleCenter(newCenter);
  };

  //@ts-ignore
  const handleMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    //@ts-ignore
    setCircleCenter({ lat: newLat, lng: newLng });
  };

  const [showEditMarker, setShowEditMarker] = useState(false);

  useEffect(() => {
    if (activeGeozoneId) {
      setMapKey((mapKey) => mapKey + 1);
    }
  }, [activeGeozoneId]);

  useEffect(() => {
    if (!isEmpty(geozones)) {
      //@ts-ignore
      if (objects?.length > 0) {
        setMapZoom(12);
      } else {
        if (geozones.length > 0 && activeGeozoneId && creationMode === "edit") {
          const getActiveGeoZone = geozones.find(
            (geoZone) => geoZone.uniqueId === activeGeozoneId
          ) || { geoJson: "" };

          const geoJSON = JSON.parse(getActiveGeoZone?.geoJson);

          const coords =
            geoJSON.geometry?.coordinates || geoJSON.Geometry?.Coordinates;

          const paths = transformGeoJSONCoordsToPaths(coords);

          const transformedCoords = coords?.map((item: any) => ({
            latitude: item[0],
            longitude: item[1],
          }));

          if (paths && paths.length > 0) {
            const zoomLevel = getBoundsZoomLevel(
              transformedCoords,
              getElementSize(alarmContainerRef.current)
            );

            var bounds = new google.maps.LatLngBounds();

            paths.map((coord) =>
              bounds.extend({ lat: coord.lat, lng: coord.lng })
            );

            mapRef?.fitBounds(bounds);
            //@ts-ignore
            mapRef?.setZoom(zoomLevel);

            const properties = geoJSON.properties || geoJSON.Properties;

            if (!isEmpty(properties)) {
              const centerPoint =
                properties.centerPoint || properties.CenterPoint;

              const center = {
                lat: centerPoint[0] || 0,
                lng: centerPoint[1] || 0,
              };
              //@ts-ignore
              setCircleCenter(center);
              setShowEditMarker(true);
            }
          }
        }
      }
    }
  }, [mapRef]);

  useEffect(() => {
    if (
      creationMode === "edit" &&
      activeGeozoneId &&
      drawingShape === "circle"
    ) {
      setShowEditMarker(true);
    } else setShowEditMarker(false);
  }, [creationMode, circleCenter, activeGeozoneId, drawingShape]);

  const renderPolygons = () => {
    return (
      geozones &&
      geozones.map((geozone) => {
        const geoJSON = JSON.parse(geozone.geoJson);

        if (!isObject(geoJSON)) return null;

        const editMode = geozone.uniqueId === activeGeozoneId;
        const geoJSONProperties = geoJSON?.properties || geoJSON?.Properties;

        if (!isEmpty(geoJSONProperties)) {
          const APIRadius =
            geoJSONProperties.radius || geoJSONProperties.Radius;
          const centerPoint =
            geoJSONProperties.centerPoint || geoJSONProperties.CenterPoint;

          if (!APIRadius || !centerPoint) return null;

          const center = {
            lat: centerPoint[0] || 0,
            lng: centerPoint[1] || 0,
          };

          return (
            <React.Fragment key={geozone.uniqueId}>
              <CircleF
                options={{
                  ...POLYGON_OPTIONS,
                  ...(editMode ? CIRCLE_HIGHLIGHT_OPTIONS : {}),
                }}
                radius={editMode ? circleSize : APIRadius}
                center={editMode ? circleCenter : center}
                onLoad={(circle) => {
                  if (editMode) onCircleLoad(circle, APIRadius);
                }}
                onUnmount={() => setCircle(null)}
              />
              {showEditMarker && (
                <Marker
                  //@ts-ignore
                  position={circleCenter}
                  draggable={true}
                  onDragEnd={handleMarkerDragEnd}
                />
              )}
            </React.Fragment>
          );
        } else {
          return (
            <PolygonF
              key={geozone.uniqueId}
              paths={transformGeoJSONCoordsToPaths(
                geoJSON.geometry?.coordinates || geoJSON.Geometry?.Coordinates
              )}
              options={{
                ...POLYGON_OPTIONS,
                ...(editMode ? POLYGON_HIGHLIGHTS_OPTIONS : {}),
              }}
              editable={editMode}
              // Event used when manipulating and adding points
              onMouseUp={onPolygonEdit}
              onLoad={editMode ? onPolygonLoad : undefined}
              onUnmount={onPolygonUnmount}
            />
          );
        }
      })
    );
  };

  const renderMap = () => (
    <GeozonesMapContainer ref={alarmContainerRef}>
      <IconsWrapper showSearchTopbar={showSearchTopbar}>
        <IconTooltip title="Show Associated Accounts" arrow>
          <IconBox
            id="show-accounts-objects"
            onClick={() => setShowAccountsObjects((prevState) => !prevState)}
          >
            {showAccountsObjects ? (
              <AccountPersonBlack />
            ) : (
              <AccountPersonGray />
            )}
          </IconBox>
        </IconTooltip>
      </IconsWrapper>
      {showSearchTopbar && (
        <PlacesSearchTopbar
          onLocationChange={onLocationChange}
          onCloseClick={onDiscardChangesHandler}
          creationMode={creationMode}
          primaryButton={{
            text: creationMode === "add" ? "Create" : "Update",
            variant: "contained",
            onClick: () => {
              if (drawingShape === "circle") {
                onCircleComplete(circle as google.maps.Circle, creationMode);
                toggleGeozoneFormOpen();
              } else {
                toggleGeozoneFormOpen();
              }
            },
            disabled: drawingMode && drawingShape !== "circle",
          }}
          secondaryButton={{
            text: "Discard",
            variant: "outlined",
            onClick: onDiscardChangesHandler,
          }}
          drawingShape={drawingShape}
          setDrawingShape={setDrawingShape}
          handleShapeChange={handleShapeChange}
          handleResetShape={handleResetShape}
          circleSize={circleSize}
          setCircleSize={setCircleSize}
        />
      )}
      <GoogleMap
        key={mapKey}
        center={circleCenter}
        zoom={mapZoom}
        onLoad={onMapLoad}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{ styles: googleMapStyles }}
        onClick={
          (drawingMode || creationMode === "edit") && drawingShape === "circle"
            ? handleMapClick
            : undefined
        }
      >
        {showAccountsObjects && (
          <MapViewport
            map={mapRef}
            markers={customMarkers}
            handleMarkerClick={handleMarkerClick}
            selectedMarker={selectedMarker}
          />
        )}
        {renderPolygons()}
        {drawingMode && drawingShape === "circle" && (
          <Fragment>
            <CircleF
              options={{
                ...CIRCLE_OPTIONS,
                ...HIGHLIGHTS_COLORS,
                zIndex: 2,
              }}
              radius={circleSize}
              center={circleCenter}
              onLoad={(circle) => {
                setCircle(circle);
              }}
              onUnmount={(circle) => setCircle(null)}
            />
            <Marker
              //@ts-ignore
              position={circleCenter}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
            />
          </Fragment>
        )}
        {objects && (
          <Markers
            objects={objects}
            selectedMarker={selectedMarker}
            handleMarkerClick={handleMarkerClick}
          />
        )}
        <DrawingManagerF
          drawingMode={
            drawingMode
              ? drawingShape !== "circle"
                ? google.maps.drawing.OverlayType.POLYGON
                : null
              : null
          }
          options={{
            drawingControl: false,
            drawingControlOptions: {
              drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              ...POLYGON_OPTIONS,
              ...HIGHLIGHTS_COLORS,
            },
            map: mapRef,
          }}
          onLoad={(drawingManager: google.maps.drawing.DrawingManager) => {
            setDrawingManager(drawingManager);
          }}
          onPolygonComplete={onPolygonComplete}
          onCircleComplete={onCircleComplete}
        />
      </GoogleMap>
      <GeozoneForm
        open={geozoneFormOpen}
        onClose={onCloseGeozoneForm}
        onSubmitHandler={onSubmitHandler}
        submitting={submitting}
        activeGeozoneId={activeGeozoneId}
      />
    </GeozonesMapContainer>
  );

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <PageLoader />;
};

export default GeozonesMap;
