import React, { Fragment, useMemo, memo, useState } from "react";
import {
  MarkerClustererF,
  OverlayViewF,
  OverlayView,
} from "@react-google-maps/api";
import { MarkerBlip, MarkerLabel } from "../../Map/Map.styled";
import ObjectMarker from "../ObjectMarker";
import {
  ClusterBoxContainer,
  ClusterHeading,
  ClusterListing,
  AddressText,
  TitleText,
} from "./MarkerClusterer.styled";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setActiveObjectId } from "app/features";
import { useAppDispatch } from "hooks";

const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  zoomOnClick: false,
};

const getPixelPositionOffset = () => ({
  x: 20,
  y: -29,
});

interface CustomMarkerClustererProps {
  map: google.maps.Map | undefined;
  markers: Array<any>;
  selectedMarkerLoading: boolean;
  showObjectNames: boolean;
  showClusters: boolean;
  selectedCluster: any;
  setSelectedCluster: (clusterData: any) => void;
}

const MarkerClusterer: React.FC<CustomMarkerClustererProps> = ({
  map,
  markers,
  selectedMarkerLoading,
  showObjectNames,
  showClusters,
  setSelectedCluster,
  selectedCluster,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [clusterObjects, setClusterObjects] = useState<any>([]);
  const visibleMarkers = useMemo(() => {
    //@ts-ignore
    if (!map || map.zoom <= 13) return markers;
    const mapBounds = map.getBounds();
    return markers.filter((marker) =>
      mapBounds?.contains(new window.google.maps.LatLng(marker.lat, marker.lng))
    );
  }, [map, markers]);

  const handleClusterClick = (cluster: any) => {
    dispatch(setActiveObjectId(null));
    const clickedMarkers = cluster.getMarkers();
    const _clusterObjects: any = [];
    clickedMarkers.map((item: any) => {
      const foundObject = visibleMarkers.find(
        (marker) =>
          marker.lat === item.position.lat() &&
          marker.lng === item.position.lng()
      );
      _clusterObjects.push(foundObject);
    });
    setClusterObjects(_clusterObjects);
    setSelectedCluster({
      position: cluster.getCenter(),
    });
  };

  const renderOverlay = (marker: any, clusterer: any, index: number) => {
    return (
      <Fragment key={`${index}`}>
        <ObjectMarker
          marker={marker}
          selectedMarkerLoading={selectedMarkerLoading}
          clusterer={clusterer}
          setSelectedCluster={setSelectedCluster}
        />
        {/* @ts-ignore */}
        {map?.zoom > 10 && (
          <OverlayViewF
            position={{ lat: marker.lat, lng: marker.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            {showObjectNames && (
              <Fragment>
                <MarkerLabel>{marker?.name || "N/A"}</MarkerLabel>
                <MarkerBlip></MarkerBlip>
              </Fragment>
            )}
          </OverlayViewF>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {showClusters && markers.length > 1 ? (
        <MarkerClustererF
          options={options}
          minimumClusterSize={5}
          onClick={handleClusterClick}
        >
          {(clusterer) => (
            <>
              {visibleMarkers &&
                visibleMarkers?.map((marker: any, i: number) =>
                  renderOverlay(marker, clusterer, i)
                )}
            </>
          )}
        </MarkerClustererF>
      ) : (
        visibleMarkers &&
        visibleMarkers?.map((marker: any, i: number) =>
          renderOverlay(marker, undefined, i)
        )
      )}

      {selectedCluster && showClusters && (
        <OverlayViewF
          position={selectedCluster.position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <ClusterListing>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={4}>
                <ClusterHeading>
                  Objects ({clusterObjects?.length || 0})
                </ClusterHeading>
              </Grid>
              <Grid mr={1} item xs={1}>
                <IconButton onClick={() => setSelectedCluster(null)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            {clusterObjects.map((object: any, index: number) => (
              <ClusterBoxContainer key={`${index}`}>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <img
                      src={object?.iconUrl}
                      width={"24px"}
                      height={"24px"}
                      alt="obj icon"
                    />
                    <TitleText
                      onClick={() =>
                        navigate({
                          pathname: `/dashboard/objects/details/${object?.uniqueId}`,
                        })
                      }
                    >
                      {object?.name}
                    </TitleText>
                  </Stack>
                  <AddressText>ID {object?.aNumber}</AddressText>
                </Stack>
              </ClusterBoxContainer>
            ))}
          </ClusterListing>
        </OverlayViewF>
      )}
    </Fragment>
  );
};

export default memo(MarkerClusterer);
