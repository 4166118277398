import { isEmpty } from "lodash";
import { useJsApiLoader } from "@react-google-maps/api";
import { types } from "@vilocnv/allsetra-core";

const GOOGLE_MAPS_LIBRARIES = ["drawing"] as any[];

export const useGoogleMapsLoader = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
    libraries: GOOGLE_MAPS_LIBRARIES,
    language: "nl",
  });

  return { isLoaded, loadError };
};

function latRad(lat: any) {
  var sin = Math.sin((lat * Math.PI) / 180);
  var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

function zoom(mapPx: any, worldPx: any, fraction: any) {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
}

export function getBoundsZoomLevel(
  coords: Array<{ latitude: number; longitude: number }>,
  mapDim: { height: number; width: number }
) {
  try {
    var bounds = new google.maps.LatLngBounds();

    coords.map((coord) =>
      bounds.extend({ lat: coord.latitude, lng: coord.longitude })
    );

    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  } catch (e: any) {
    console.log("getBoundsZoomLevel error", e.message);
  }
}

export const extractObjectsDataAsMarkersForMap = (
  objects: Array<types.IObject>
) => {
  if (isEmpty(objects)) return [];

  return objects
    .filter(
      (item: any) =>
        item.location &&
        item.location.latitude !== null &&
        item.location.longitude !== null &&
        item.location.latitude !== 0 &&
        item.location.longitude !== 0
    )
    .map((item: any) => {
      const { latitude, longitude } = item?.location;
      const iconUrl = item?.objectType?.icon?.url;

      const originalDate = new Date(item.location.date);
      const formattedDate = originalDate.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });

      return {
        //due to icons displacement issue
        lat: latitude - 0.00001,
        lng: longitude,
        iconUrl,
        name: item?.name,
        aNumber: item?.aNumber,
        accuracy: item?.location.accuracy,
        formattedDate,
        lastReportedSpeed: item?.lastReportedSpeed,
        uniqueId: item?.uniqueId,
        resolvedAddress: item?.location?.resolvedAddress || "N/A",
      };
    });
};
