import { FC, useEffect, useMemo } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import {
  geozoneFormInitialValues,
  geozoneFormValidactionSchema,
} from "app/data/helpers";
import { ModalProps } from "@vilocnv/allsetra-core";
import InnerForm from "./children/InnerForm";

// Data
import { IAddGeozone } from "app/data/types";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getActiveGeozoneInitialValuesForForm,
  selectDrawerSelectedAccountId,
} from "app/data/selectors";
import { isEmpty } from "lodash";
import { getGeozoneAssignedObjectsThunk } from "app/features";

export type GeozoneFormProps = Omit<ModalProps, "title" | "children"> & {
  onSubmitHandler: (
    values: IAddGeozone,
    formikHelpers: FormikHelpers<IAddGeozone>
  ) => void;
  submitting?: boolean;
  activeGeozoneId: string | null;
  activeObjectId?: any;
  isObjectDetailsPage?: boolean;
};

const GeozoneForm: FC<GeozoneFormProps> = ({
  open,
  onClose,
  onSubmitHandler,
  submitting = false,
  activeGeozoneId,
  activeObjectId,
  isObjectDetailsPage = false,
}) => {
  const dispatch = useAppDispatch();
  const activeGeozoneInitialValues: any = useAppSelector(
    getActiveGeozoneInitialValuesForForm
  );

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  useEffect(() => {
    if (activeGeozoneId) {
      dispatch(
        getGeozoneAssignedObjectsThunk({
          accountId: drawerSelectedAccountId ?? "",
          geozoneId: activeGeozoneId ?? "",
        })
      );
    }
  }, [activeGeozoneId]);

  const isInEdit = isEmpty(activeGeozoneInitialValues)
    ? false
    : !isEmpty(activeGeozoneInitialValues?.name);

  const initialValues = useMemo(() => {
    return isInEdit ? activeGeozoneInitialValues : geozoneFormInitialValues;
  }, [activeGeozoneInitialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={geozoneFormValidactionSchema}
      onSubmit={onSubmitHandler}
      enableReinitialize
      validateOnMount
    >
      <Form>
        <InnerForm
          open={open}
          onClose={onClose}
          isInEdit={isInEdit}
          submitting={submitting}
          activeObjectId={activeObjectId}
          isObjectDetailsPage={isObjectDetailsPage}
        />
      </Form>
    </Formik>
  );
};

export default GeozoneForm;
