import { styled, Box, Typography } from "@mui/material";

export const ClusterListing = styled(Box)({
  width: "388px",
  // height: "408px",
  backgroundColor: "white",
  zIndex: 1000,
  borderRadius: "10px",
  border: "1px solid #DFE5F4",
  position: "fixed",
  overflowY: "auto",
});

export const ClusterHeading = styled(Typography)({
  fontSize: "14px",
  lineHeight: "15.68px",
  color: "#76828F",
  fontFamily: "Gilroy",
  marginLeft: "20px",
  marginTop: "5px",
});

export const ClusterBoxContainer = styled(Box)({
  borderBottom: "1px solid #DFE5F4",
  marginLeft: "22px",
  marginRight: "22px",
  paddingBottom: "5px",
});

export const AddressText = styled("span")({
  color: "#323946",
  fontFamily: "Gilroy",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "16.24px",
  marginLeft: "4px",
  marginTop: "10px",
  marginBottom: "12px",
});

export const TitleText = styled(Typography)({
  marginLeft: "6px",
  display: "inline-block",
  color: "#1D1F2B",
  fontFamily: "Gilroy",
  fontSize: "16px",
  fontWeight: "bold",
  lineHeight: "18.4px",
  width: "220px",
  wordWrap: "break-word",
  cursor: "pointer",
});
