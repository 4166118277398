import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import {
  getAllAccountsThunk,
  getSpecificAccountThunk,
  getAccountsIndustriesThunk,
  getAccountsTypesThunk,
  getAllAccountGroupsThunk,
  getAllAccountObjectsThunk,
  getSpecificAccountUserThunk,
  getAccountPointsOfInterestsThunk,
  getAccountFeaturesThunk,
  getUsersBasicInfoThunk,
} from "./accountsActions";
import { IFeatures } from "app/data/types";
import { featuresInitialData } from "app/data/helpers";

export interface IAccountsState {
  loading: boolean;

  activeAccountId: string | null;
  specificAccount: types.IAccount | null;
  allAccounts: Array<any>;
  allDrawerAccounts: Array<any>;
  selectedAccounts: [];

  accountsIndustriesLoading: boolean;
  accountsIndustries: Array<any>;
  accountsTypesLoading: boolean;
  accountsTypes: Array<any>;
  accountGroups: Array<any>;
  accountObjects: Array<any>;
  accountUser: Array<any>;
  accountFeatures: Array<any>;

  accountPointsOfInterestsLoading: boolean;
  accountPointsOfInterests: Array<any>;

  usersBasicInfo: Array<any>;

  features: IFeatures;
}

const initialState: IAccountsState = {
  loading: false,

  activeAccountId: null,
  specificAccount: null,
  allAccounts: [],
  allDrawerAccounts: [],
  selectedAccounts: [],

  accountsIndustriesLoading: false,
  accountsIndustries: [],
  accountsTypesLoading: false,
  accountsTypes: [],
  accountGroups: [],
  accountObjects: [],
  accountUser: [],
  accountFeatures: [],

  usersBasicInfo: [],

  accountPointsOfInterestsLoading: false,
  accountPointsOfInterests: [],

  features: featuresInitialData,
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setActiveAccountId: (state, action: PayloadAction<string | null>) => {
      state.activeAccountId = action.payload;
    },
    resetActiveAccountState: (state) => {
      state.activeAccountId = null;
      state.specificAccount = null;
    },
    resetAllAccounts: (state) => {
      state.allAccounts = [];
      state.selectedAccounts = [];
      state.allDrawerAccounts = [];
    },
  },
  extraReducers: (builder) => {
    // Get All Accounts Action Cases
    builder.addCase(getAllAccountsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllAccountsThunk.fulfilled, (state, action: any) => {
      state.selectedAccounts = action.payload.data;

      state.allDrawerAccounts = action.payload.data;

      if (!action.payload.tenantIdExists) {
        state.allAccounts = action.payload.data;
      }

      state.loading = false;
    });

    builder.addCase(getAllAccountsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specific Account Action Cases
    builder.addCase(getSpecificAccountThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificAccountThunk.fulfilled, (state, action) => {
      state.specificAccount = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificAccountThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Accounts Industries Thunk Action Cases
    builder.addCase(getAccountsIndustriesThunk.pending, (state) => {
      state.accountsIndustriesLoading = true;
    });

    builder.addCase(getAccountsIndustriesThunk.fulfilled, (state, action) => {
      state.accountsIndustries = action.payload;
      state.accountsIndustriesLoading = false;
    });

    builder.addCase(getAccountsIndustriesThunk.rejected, (state) => {
      state.accountsIndustriesLoading = false;
    });

    // Get Accounts Types Thunk Action Cases
    builder.addCase(getAccountsTypesThunk.pending, (state) => {
      state.accountsTypesLoading = true;
    });

    builder.addCase(getAccountsTypesThunk.fulfilled, (state, action) => {
      state.accountsTypes = action.payload;
      state.accountsTypesLoading = false;
    });

    builder.addCase(getAccountsTypesThunk.rejected, (state) => {
      state.accountsTypesLoading = false;
    });

    // Get All Account Groups Action Cases
    builder.addCase(getAllAccountGroupsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllAccountGroupsThunk.fulfilled, (state, action) => {
      state.accountGroups = action.payload || [];
      state.loading = false;
    });

    builder.addCase(getAllAccountGroupsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All Account Objects Action Cases
    builder.addCase(getAllAccountObjectsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllAccountObjectsThunk.fulfilled, (state, action) => {
      state.accountObjects = action.payload || [];
      state.loading = false;
    });

    builder.addCase(getAllAccountObjectsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specific Account User Action Cases
    builder.addCase(getSpecificAccountUserThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificAccountUserThunk.fulfilled, (state, action) => {
      state.accountUser = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificAccountUserThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specific Account User Action Cases
    builder.addCase(getAccountPointsOfInterestsThunk.pending, (state) => {
      state.accountPointsOfInterestsLoading = true;
    });

    builder.addCase(
      getAccountPointsOfInterestsThunk.fulfilled,
      (state, action) => {
        state.accountPointsOfInterests = action.payload;
        state.accountPointsOfInterestsLoading = false;
      }
    );

    builder.addCase(getAccountPointsOfInterestsThunk.rejected, (state) => {
      state.accountPointsOfInterestsLoading = false;
    });

    // Get Account Features Action Cases
    builder.addCase(getAccountFeaturesThunk.pending, (state) => {
      state.loading = true;
      state.accountFeatures = [];
      state.features = featuresInitialData;
    });

    builder.addCase(getAccountFeaturesThunk.fulfilled, (state, action) => {
      state.accountFeatures = action.payload;

      action.payload.forEach((feature: any) => {
        switch (feature.uniqueId) {
          case "d5547222-a5b4-417a-8344-93e9084ec8e5":
            state.features.isRidesEnabled = true;
            break;
          case "d7cce48d-a92d-4729-9b70-e9f5b1390509":
            state.features.isAutomaticReportsEnabled = true;
            break;
        }
      });

      state.loading = false;
    });

    builder.addCase(getAccountFeaturesThunk.rejected, (state) => {
      state.accountFeatures = [];
      state.features = featuresInitialData;
      state.loading = false;
    });

    // getUsersBasicInfoThunk
    builder.addCase(getUsersBasicInfoThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsersBasicInfoThunk.fulfilled, (state, action) => {
      state.usersBasicInfo = action.payload || [];
      state.loading = false;
    });
    builder.addCase(getUsersBasicInfoThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export * from "./accountsActions";
export * from "./accountsQueries";
export const { setActiveAccountId, resetActiveAccountState, resetAllAccounts } =
  accountsSlice.actions;

export default accountsSlice.reducer;
