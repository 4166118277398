import React, { FC, useEffect, useState } from "react";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { ExportGrey, Export, ExcelIcon, PDFIcon } from "assets/icons";
import {
  CSVLink,
  MuiButton,
  PDFBox,
} from "pages/dashboard/Reports/Reports.styled";

interface ExportButtonMenuProps {
  tableHeaders: { label: string; key: string }[];
  generateCSVData?: any[];
  formattedData: any;
  generatePDF: (data: any) => void;
  disabled?: boolean;
  exportButtonLoading?: boolean;
}

const ExportButtonMenu: FC<ExportButtonMenuProps> = ({
  generateCSVData,
  tableHeaders,
  formattedData,
  generatePDF,
  disabled = false,
  exportButtonLoading = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (exportButtonLoading) setAnchorEl(null);
  }, [exportButtonLoading]);

  return (
    <>
      {exportButtonLoading ? (
        <CircularProgress />
      ) : (
        <>
          <MuiButton
            disabled={disabled}
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="text"
          >
            {disabled ? <ExportGrey /> : <Export />}
            Export
          </MuiButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {generateCSVData && (
              <MenuItem>
                <CSVLink
                  data={generateCSVData}
                  headers={tableHeaders}
                  filename={"Allsetra Report.csv"}
                  target="_blank"
                >
                  <ExcelIcon />
                  CSV
                </CSVLink>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                generatePDF(formattedData);
              }}
            >
              <PDFBox>
                <PDFIcon />
                PDF
              </PDFBox>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default ExportButtonMenu;
