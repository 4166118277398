import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SupportingDrawerLayout,
  GeozonesListing,
  types,
  DeleteConfirmationModal,
  toast,
  PageLoader,
} from "@vilocnv/allsetra-core";
import { Box, useTheme } from "@mui/material";
import GeozonesMap from "components/maps/GeozonesMap/GeozonesMap";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectGeozonesState,
  selectDrawerSelectedAccountId,
} from "app/data/selectors";
import {
  deleteGeozoneThunk,
  getAllGeozonesThunk,
  getAllObjectsThunk,
  setActiveGeozone,
  setActiveGeozoneId,
} from "app/features";
import { SignalRService } from "app/data/services";

const Geozones: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const {
    allGeozones,
    loading: allGeozonesLoading,
    geozoneAssignedObjects,
  } = useAppSelector(selectGeozonesState);

  // Local State
  const [showSearchTopbar, setShowSearchTopbar] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [drawingMode, setDrawingMode] = useState(false);
  const [drawingShape, setDrawingShape] = useState("circle");
  const [circleSize, setCircleSize] = useState(10000);
  const [creationMode, setCreationMode] = useState<"add" | "edit" | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedGeozoneId, setSelectedGeozoneId] = useState<string | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    dispatch(setActiveGeozoneId(null));
  }, []);

  useEffect(() => {
    dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
    dispatch(getAllObjectsThunk(drawerSelectedAccountId || ""));
  }, [drawerSelectedAccountId]);

  const resetToGeozonesListing = useCallback(() => {
    setShowSearchTopbar(false);
    setDrawerOpen(true);
    setDrawingMode(false);
  }, []);

  const onAddGeozone = useCallback(() => {
    setDrawerOpen(false);
    setShowSearchTopbar(true);
    setDrawingMode(true);
    setCreationMode("add");
  }, []);

  const onEditGeozone = useCallback((geozone: types.IGeozone) => {
    if (isEmpty(geozone)) return null;
    setDrawerOpen(false);
    setShowSearchTopbar(true);
    setCreationMode("edit");
    dispatch(setActiveGeozoneId(geozone?.uniqueId || ""));
  }, []);

  const openDeleteConfirmationModal = useCallback((geozone: types.IGeozone) => {
    setSelectedGeozoneId(geozone.uniqueId);
    setOpenDeleteModal(true);
  }, []);

  const onDeleteGeozone = async () => {
    setIsDeleting(true);

    const { type } = await dispatch(
      deleteGeozoneThunk({
        accountId: drawerSelectedAccountId ?? "",
        accountGeofenceId: selectedGeozoneId ?? "",
      })
    );
    if (type === "geozones/deleteGeozoneThunk/fulfilled") {
      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.GeozoneRemovedFromAccountEvent
        ) {
          setIsDeleting(false);
          setOpenDeleteModal(false);
          toast.success("Geozone has been deleted successfully.");
          dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));

          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      setIsDeleting(false);
    }
  };

  const onCardClick = useCallback((geozone: types.IGeozone) => {
    dispatch(setActiveGeozone(geozone));
    navigate(`/dashboard/geozones/${geozone.uniqueId}`);
  }, []);

  return (
    <main>
      <SupportingDrawerLayout open={drawerOpen}>
        {allGeozonesLoading ? (
          <PageLoader sx={{ width: "100%" }} />
        ) : (
          <GeozonesListing
            geozones={allGeozones ?? []}
            onAddGeozone={onAddGeozone}
            onEditGeozone={onEditGeozone}
            onDeleteGeozone={openDeleteConfirmationModal}
            onCardClick={onCardClick}
            theme={theme}
          />
        )}
      </SupportingDrawerLayout>
      <Box>
        <GeozonesMap
          creationMode={creationMode}
          geozones={allGeozones ?? []}
          objects={creationMode === "edit" ? geozoneAssignedObjects : []}
          showSearchTopbar={showSearchTopbar}
          drawingMode={drawingMode}
          setDrawingMode={setDrawingMode}
          resetToGeozonesListing={resetToGeozonesListing}
          drawingShape={drawingShape}
          setDrawingShape={setDrawingShape}
          circleSize={circleSize}
          setCircleSize={setCircleSize}
        />
      </Box>
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to delete a geozone"
        subTitle="Do you really want to delete this geozone? This process cannot be undone."
        primaryBtnProps={{
          id: "geozone-delete",
          onClick: onDeleteGeozone,
          loading: isDeleting,
        }}
      />
    </main>
  );
};

export default Geozones;
