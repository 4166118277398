import { FC, useEffect, useMemo, useState } from "react";
import { Stack, useTheme } from "@mui/material";
import {
  FormikCheckbox,
  FormikInputField,
  FormikSelectField,
  FormikSelectSearchField,
  Modal,
  ModalProps,
  utils,
} from "@vilocnv/allsetra-core";
import { useFormikContext } from "formik";
import { IAddGeozone } from "app/data/types";
import { GeozonesSvg } from "assets/icons";
import { useAppDispatch, useAppSelector, useSelectSearch } from "hooks";
import {
  selectDashboardIconsState,
  selectDrawerSelectedAccountId,
  selectGeozonesState,
  selectQueriedObjectsState,
} from "app/data/selectors";
import { getIconsByCategoryThunk, getObjectsByQueryThunk } from "app/features";

type Props = Omit<ModalProps, "title" | "children"> & {
  isInEdit: boolean;
  submitting?: boolean;
  activeObjectId?: any;
  isObjectDetailsPage?: boolean;
};

const InnerForm: FC<Props> = ({
  open,
  onClose,
  isInEdit,
  submitting,
  activeObjectId,
  isObjectDetailsPage = false,
  ...rest
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { setFieldValue } = useFormikContext<any>();

  const { resetForm, isSubmitting, isValid, handleSubmit, values } =
    useFormikContext<IAddGeozone>();

  const { iconsByCategory: icons, loading: iconsLoading } = useAppSelector(
    selectDashboardIconsState
  );
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { geozoneAssignedObjects, geozoneAssignedObjectsLoading } =
    useAppSelector(selectGeozonesState);
  const { objects, loading: objectsLoading } = useAppSelector(
    selectQueriedObjectsState
  );

  const selectedLocalObjects = useSelectSearch(
    //@ts-ignore
    values?.assignedObjects,
    objects
  );

  useEffect(() => {
    dispatch(getIconsByCategoryThunk(1));

    if (activeObjectId && isObjectDetailsPage) {
      setFieldValue("assignedObjects", [activeObjectId.uniqueId]);
      setFieldValue("assignedToAllObjects", false);
    }
  }, []);

  const iconsOptions = useMemo(() => {
    return (
      icons &&
      icons.map((icon) => ({
        label: (
          <img src={icon.url} key={icon.uniqueId} alt="Icon" width="22.5px" />
        ),
        value: icon.uniqueId,
      }))
    );
  }, [icons]);

  const onCloseHandler = () => {
    onClose();
    resetForm();
  };

  const handleAssignedObjectsSearch = (value: string) => {
    dispatch(
      getObjectsByQueryThunk({
        accountId: drawerSelectedAccountId || "",
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "name", value, type: 0 }],
        },
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      headerIcon={<GeozonesSvg />}
      headerIconBgColor={"#F9FBFF"}
      title={isInEdit ? "Updating Geozone" : "Creating Geozone"}
      subTitle={
        "You are almost done. Before saving geozone you need to give it a name."
      }
      primaryBtnProps={{
        id: isInEdit ? "geozone-edit" : "geozone-add",
        type: "submit",
        text: isInEdit ? "Update" : "Create",
        loading: isSubmitting || submitting,
        disabled: !isValid,
        // @ts-ignore

        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: "Cancel",
        onClick: onCloseHandler,
      }}
      theme={theme}
      {...rest}
    >
      <Stack spacing={2}>
        <FormikInputField
          theme={theme}
          label="Geozone name"
          name="name"
          placeholder="Geozone name"
          required
        />
        <FormikSelectField
          theme={theme}
          label="Geozone icon"
          name="iconId"
          placeholder="Geozone icon"
          options={iconsOptions ?? []}
          optionLabelKey="label"
          optionValueKey="value"
          loading={iconsLoading}
          required
        />
        <FormikCheckbox
          name="assignedToAllObjects"
          label="Select all objects"
        />
        {!values.assignedToAllObjects && (
          <FormikSelectSearchField
            fetchOnDebounce={handleAssignedObjectsSearch}
            label="Assigned objects"
            name="assignedObjects"
            placeholder="Assigned objects"
            options={[
              ...selectedLocalObjects,
              ...geozoneAssignedObjects,
              isObjectDetailsPage && activeObjectId ? activeObjectId : {},
            ]}
            optionLabelKey="name"
            optionValueKey="uniqueId"
            loading={geozoneAssignedObjectsLoading || objectsLoading}
            searchable
            multiple
          />
        )}
      </Stack>
    </Modal>
  );
};

export default InnerForm;
