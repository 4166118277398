// @ts-nocheck
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { DashboardLayout, PageLoader, types } from "@vilocnv/allsetra-core";
import * as Sentry from "@sentry/react";

// Data
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
  useDispatchOnMount,
  useSetLangOnSettingsChange,
} from "hooks";
import {
  getAccountFeaturesThunk,
  getAllAccountsThunk,
  resetAllAccounts,
  setDrawerCollapseState,
  setDrawerSelectedAccount,
  setFromDashboard,
} from "app/features";
import {
  selectAccountsForDrawer,
  selectIsDrawerCollapsed,
  selectDrawerSelectedAccountState,
  selectWhiteLabelsState,
  selectAccountFeaturesState,
} from "app/data/selectors";
import { getDrawerMenuItems, getDrawerSubMenuLists } from "app/data/constants";
import { isEmpty } from "lodash";
import SignalRService, {
  signalRConnectionEvent,
} from "app/data/services/SignalRService";
import { clearIndexedDB } from "app/data/helpers";

export interface ProtectedRouteProps {
  redirectTo: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ redirectTo }) => {
  const { pathname, search } = useLocation();
  const { instance } = useMsal();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Global State
  const isDrawerCollapsed = useAppSelector(selectIsDrawerCollapsed);
  const { allAccounts, allDrawerAccounts, transformedAccounts } =
    useAppSelector(selectAccountsForDrawer);
  const { drawerSelectedAccountId, drawerSelectedAccountName } = useAppSelector(
    selectDrawerSelectedAccountState
  );
  const { whiteLabel, loading: whiteLabelLoading } = useAppSelector(
    selectWhiteLabelsState
  );

  const { features } = useAppSelector(selectAccountFeaturesState);

  // Local State
  const [renderCycle, setRenderCycle] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { t } = useTranslation();

  useSetLangOnSettingsChange();

  useDispatchOnMount(
    getAllAccountsThunk,
    allDrawerAccounts.length ? undefined : true
  );

  const drawerMenuItems = useMemo(() => getDrawerMenuItems(t), [t]);

  const drawerMenuSubItems = useMemo(
    () => getDrawerSubMenuLists(t, features, drawerSelectedAccountId),
    [t, features, drawerSelectedAccountId]
  );

  useEffect(() => {
    if (isEmpty(drawerSelectedAccountId)) {
      const account = allDrawerAccounts.length ? allDrawerAccounts[0] : null;

      const formattedAccount = {
        id: account?.accountId || null,
        name: account?.accountName || null,
      };

      dispatch(setDrawerSelectedAccount(formattedAccount));
    } else {
      dispatch(getAccountFeaturesThunk(drawerSelectedAccountId));
    }
  }, [allDrawerAccounts, drawerSelectedAccountId]);

  const selectedAccount = useMemo(
    () => ({
      id: drawerSelectedAccountId,
      name: drawerSelectedAccountName,
    }),
    [drawerSelectedAccountId, drawerSelectedAccountName]
  );

  const toggleDrawerCollapseState = () => {
    dispatch(setDrawerCollapseState(!isDrawerCollapsed));
  };

  const onAccountChange = useCallback((value: types.ISelectFieldValue) => {
    dispatch(setDrawerSelectedAccount(value));
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await dispatch(setFromDashboard(false));
      await dispatch(resetAllAccounts());
      Sentry.setUser(null);
      await clearIndexedDB();
      window.localStorage.clear();
      await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    } catch (error) {
      console.error("Error during logout process:", error);
    }
  }, [instance]);

  const fullPath = `${pathname}${search}`;

  window.onbeforeunload = () => {
    sessionStorage.setItem("oldPathname", fullPath);
  };

  useEffect(() => {
    if (pathname?.split("/dashboard")[1]) {
      sessionStorage.setItem("pathname", fullPath);
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(setFromDashboard(true));
    const oldPath = sessionStorage.getItem("oldPathname");

    const _pathname = sessionStorage.getItem("pathname");

    if (_pathname === oldPath) {
      navigate(_pathname);
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    signalRConnectionEvent.subscribe((value) => {
      if (value === "Connected") {
        setRenderCycle((cycle) => cycle + 1);
      }

      setIsLoading(false);
    });
  }, []);

  return (
    <Fragment>
      <UnauthenticatedTemplate>
        <Navigate to={redirectTo} state={{ from: pathname }} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate key={renderCycle}>
        {whiteLabelLoading || isLoading ? (
          <PageLoader sx={{ width: "100dvw" }}></PageLoader>
        ) : (
          <DashboardLayout
            size={"large"}
            isDrawerCollapsed={isDrawerCollapsed}
            toggleDrawerCollapseState={toggleDrawerCollapseState}
            appLogoType={"customer"}
            menuList={drawerMenuItems}
            activeLinkTextColor={theme.palette.primary.main}
            activeLinkBgColor={"rgba(245, 255, 254, 0.12)"}
            showAccountsField={true}
            accounts={transformedAccounts}
            selectedAccount={selectedAccount}
            onAccountChange={onAccountChange}
            isSignalRConnected={SignalRService.hubConnection?.state}
            onSupportClick={() => {}}
            onLogoutClick={handleLogout}
            translator={t}
            whiteLabel={whiteLabel}
            showReportBugForm={isEmpty(whiteLabel)}
            subLists={drawerMenuSubItems}
          />
        )}
      </AuthenticatedTemplate>
    </Fragment>
  );
};

export default ProtectedRoute;
