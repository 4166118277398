import {
  Box,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";

export const GeozonesMapContainer = styled(Box)({
  height: "100vh",
  width: "100%",

  ".gm-style-iw.gm-style-iw-c": {
    background: "none",
    boxShadow: "none",
  },
  "button.gm-ui-hover-effect": {
    top: "6px !important",
    "& span": {
      backgroundColor: "#ffffff !important",
    },
  },
  ".gm-style-iw-d": {
    overflow: "hidden !important",
  },
  ".gm-style .gm-style-iw-tc::after": {
    background: "#192026E0",
  },
  ".gmnoprint .gm-style-mtc": {
    display: "none",
  },
  "button.gm-control-active.gm-fullscreen-control": {
    display: "none",
  },
});

export const IconBox = styled(Box)({
  padding: "3px 5px",
  backgroundColor: "white",
  cursor: "pointer",
  display: "flex",
  borderRadius: "4px",
});

export const IconsWrapper = styled(Box)<{ showSearchTopbar: boolean }>(
  ({ showSearchTopbar }) => ({
    position: "absolute",
    top: showSearchTopbar ? "92px" : "8px",
    zIndex: 2,
    right: 10,
    display: "flex",
    gap: "10px",
  })
);

export const IconTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0,0,0,0.7)",
    maxWidth: "120px",
    padding: "6px",
    textAlign: "center",
  },
}));
